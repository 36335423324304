let ul,
    UserLogin = {
        settings: {
            username: "",
            password: "",
            tokenData: "",
            progressID: "",
            waivers: [],
        },
        init: function () {
            ul = this.settings;
            this.bindUIActions();
        },
        bindUIActions: function () {
            var red_url = UserLogin.getParameterByName("Return_URL");
            var pagename_param = UserLogin.getParameterByName("pagename");
            if (red_url) {
                $("#login-alert").hide();
            } else {
                $("#login-alert").hide();
            }
            if (document.getElementById("login-form")) {
                axios
                    .get("/API/profile/")
                    .then(function (response) {
                        if (response.data.first_name) {
                            if (red_url) {
                                location.href = red_url;
                            } else {
                                if (pagename_param) {
                                    location.href = "https://legacy.usacycling.org/myusac/index.php?pagename=" + pagename_param;
                                } else {
                                    location.href = "https://legacy.usacycling.org/myusac/index.php";
                                }
                            }
                        }
                    })
                    .catch(function (error) {});

                $("input").keypress(function (e) {
                    if (e.which == 13) {
                        e.preventDefault();
                        UserLogin.loginUser();
                        return false;
                    }
                });
            }
            $(document).on("invalid.zf.abide", function (e) {
                $(".login-progress").hide();
            });

            $(".btn-login-action").on("click", function (e) {
                e.preventDefault();
                UserLogin.loginUser();
            });
            $(".btn-register-action").on("click", function (e) {
                var register_url = "https://myaccount.usacycling.org/signup";

                // if (red_url) {
                //     register_url += "?Return_URL=" + red_url;
                // }

                location.href = register_url;
            });
        },
        waiversPresent: function () {
            axios
                .get("/API/checkwv/?xfg=293381")
                .then(function (response) {
                    if (
                        response.data[0].id == "" ||
                        response.data[0].id == undefined ||
                        response.data.detail == "Invalid username/password."
                    ) {
                        return false;
                    } else {
                        location.href = "/waivers";
                        return true;
                    }
                })
                .catch(function (error) {
                    return false;
                });
        },
        loginUser: function () {
            var red_url = UserLogin.getParameterByName("Return_URL");
            var pagename_param = UserLogin.getParameterByName("pagename");
            $(".log-in-form").foundation("validateForm");

            UserLogin.username = $("#username").val();
            UserLogin.password = $("#password").val();

            $(".error-msg").hide();
            $(".error-msg").html("");

            $(".btn-search-text").toggleClass("active");
            $(".loader").toggleClass("active");

            $.ajax({
                method: "POST",
                url: "/API/login/",
                xhrFields: {
                    withCredentials: false,
                },
                data: {
                    username: UserLogin.username,
                    password: UserLogin.password,
                },
            })
                .done(function (response) {
                    var oj = JSON.parse(response);
                    if (oj.access_token) {
                        if (UserLogin.waiversPresent() == true) {
                        } else if (red_url) {
                            location.href = red_url;
                        } else {
                            if (pagename_param) {
                                location.href = "https://legacy.usacycling.org/myusac/index.php?pagename=" + pagename_param;
                            } else {
                                location.href = "https://legacy.usacycling.org/myusac/index.php";
                            }
                        }
                    } else {
                        $(".btn-search-text").toggleClass("active");
                        $(".loader").toggleClass("active");
                        $(".error-msg").html(
                            "Invalid username and/or password."
                        );
                        $(".error-msg").show();
                    }
                })
                .fail(function () {
                    $(".loader").toggleClass("active");
                    $(".btn-search-text").toggleClass("active");
                    $(".error-msg").html("Invalid username and/or password.");
                    $(".error-msg").show();
                });
        },
        getParameterByName: function (name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return "";
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        },
    };

UserLogin.init();