import Swiper from 'swiper/js/swiper.esm.bundle.js';
let cgc,
    CardGridCarousel = {
        settings: {
            swiperContainer: $(".swiper-container.swiper-card-grid"),
        },
        init: function () {
            cgc = this.settings;
            this.bindUIActions();
        },
        bindUIActions: function () {
            if (cgc.swiperContainer.length) {
                new Swiper(cgc.swiperContainer, {
                    coverflowEffect: {
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    },

                    lazy: {
                        loadPrevNext: true,
                    },

                    grabCursor: true,
                    centeredSlides: true,
                });
            }
        },
    };

CardGridCarousel.init();