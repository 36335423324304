import Swiper from 'swiper/js/swiper.esm.bundle.js';
let hc,
    HeroCarousel = {
        settings: {
            swiperContainer: $(".swiper-container.swiper-hero-carousel"),
            swiperPagination: $(".swiper-hero-carousel .swiper-pagination"),
            swiperNext: $(".swiper-button-next"),
            swiperPrev: $(".swiper-button-prev")
        },
        init: function () {
            hc = this.settings;
            this.bindUIActions();
        },
        bindUIActions: function () {
            if (hc.swiperContainer.length) {
                const swiper = new Swiper(hc.swiperContainer, {
                    loop: false,

                    pagination: {
                        el: hc.swiperPagination,
                        clickable: true,
                    },

                    navigation: {
                        nextEl: hc.swiperNext,
                        prevEl: hc.swiperPrev,
                    },

                    spaceBetween: 30,

                    lazy: {
                        loadPrevNext: true,
                    },
                    autoplay: {
                        delay: 6000,
                        disableOnInteraction: false,
                        stopOnLastSlide: true
                    },
                });
            }
        },
    };

HeroCarousel.init();