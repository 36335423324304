import Swiper from 'swiper/js/swiper.esm.bundle.js';
let fcc,
    FeatureCardCarousel = {
        init: function () {
            var featureCardContainer = $(
                ".swiper-container.swiper-feature-card"
            );
            var featureCardPagination = $(
                ".swiper-pagination.fcc-swiper-pagination"
            );
            var featureCardNext = $(
                ".swiper-button-next.fcc-swiper-button-next"
            );
            var featureCardPrev = $(
                ".swiper-button-prev.fcc-swiper-button-prev"
            );

            if (featureCardContainer.length) {
                var fccSwiper = new Swiper(featureCardContainer, {
                    slidesPerView: 4,
                    spaceBetween: 20,
                    loop: true,

                    pagination: {
                        el: featureCardPagination,
                        clickable: true,
                    },

                    navigation: {
                        nextEl: featureCardNext,
                        prevEl: featureCardPrev,
                    },

                    lazy: {
                        loadPrevNext: true,
                    },

                    breakpoints: {
                        1240: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        980: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        640: {
                            slidesPerView: 1,
                            grabCursor: true,
                            centeredSlides: true,
                            coverflowEffect: {
                                rotate: 50,
                                stretch: 0,
                                depth: 100,
                                modifier: 1,
                                slideShadows: true,
                            },
                        },
                    },
                });
            }
        },
    };

FeatureCardCarousel.init();