let ns,
    NewsletterSubscribe = {
        settings: {
            form: $(".newsletter-signup-widget form"),
            formContainer: $(".newsletter-form"),
            messageContainer: $(".newsletter-message-container"),
        },
        init: function () {
            ns = this.settings;
            this.bindUIActions();
        },
        bindUIActions: function () {
            if (ns.form.length) {
                ns.form.on("submit", function (e) {
                    e.preventDefault();

                    $.post({
                        url: '/',
                        dataType: 'json',
                        data: $(this).serialize(),
                        success: function(response) {
                            ns.formContainer.addClass("hidden");
                            ns.messageContainer.html("Thank you for subscribing!");
                        },
                        error: function(jqXHR) {
                            // The response body will be an object containing the following keys:
                            // - `message` – A high level message for the response
                            // - `submission` – An object containing data from the attempted submission
                            // - `errors` – An object containing validation errors from the submission, indexed by attribute name
                            ns.formContainer.addClass("hidden");
                            ns.messageContainer.html("Thank you for subscribing!");
                            // ns.messageContainer.html("Error");
                        }
                    });

                    // $.post("/", $(this).serialize()).done(function (data) {
                    //     if (!data.success) {
                    //         // there was an error, do something with data
                    //         ns.messageContainer.html("Error");
                    //     } else {
                    //         // Success
                    //         ns.formContainer.addClass("hidden");
                    //         ns.messageContainer.html("Thanks!");
                    //     }
                    // });
                });
            }
        },
    };

    NewsletterSubscribe.init();
