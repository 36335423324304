let rb,
    RedBox = {
        init: function () {
            rb = this.settings;
            this.bindUIActions();
        },
        bindUIActions: function () {
            var redirect_url = RedBox.getParameterByName("Return_URL");
            if (redirect_url) {
                var link = $("#loginCTA");
                link.attr(
                    "href",
                    link.attr("href") + "?Return_URL=" + redirect_url
                );
            }
        },
        getParameterByName: function (name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return "";
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        },
    };

RedBox.init();