import Swiper from 'swiper/js/swiper.esm.bundle.js';
let sc,
    SocialCarousel = {
        settings: {
            swiperContainer: $(".swiper-container.swiper-social"),
        },
        init: function () {
            sc = this.settings;
            this.bindUIActions();
        },
        bindUIActions: function () {
            if (sc.swiperContainer.length) {
                if ($(window).width() < 640) {
                    new Swiper(sc.swiperContainer, {
                        grabCursor: true,
                        centeredSlides: true,
                        slidesPerView: "auto",
                        coverflowEffect: {
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: true,
                        },
                    });
                } else {
                    var desktopSwiper = new Swiper(sc.swiperContainer, {
                        slidesPerView: 4,
                        loop: true,

                        autoplay: {
                            disableOnInteraction: false,
                        },

                        speed: 2000,
                        spaceBetween: 30,
                        freeMode: true,
                    });

                    desktopSwiper.autoplay.stop();

                    sc.swiperContainer.hover(
                        function () {
                            desktopSwiper.autoplay.start();
                        },
                        function () {
                            desktopSwiper.autoplay.stop();
                        }
                    );
                }
            }
        },
    };

SocialCarousel.init();