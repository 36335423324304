import Swiper from 'swiper/js/swiper.esm.bundle.js';
let itc,
    ImageTextCarousel = {
        init: function ($id) {
            var swiperContainer = $("#" + $id + " > .swiper-container");
            var swiperPagination = $("#" + $id + " .swiper-pagination");
            var swiperNext = $("#" + $id + " .swiper-button-next");
            var swiperPrev = $("#" + $id + " .swiper-button-prev");
            var autoplay = $("#" + $id).data("delayspeed");

            if (swiperContainer.length) {
                new Swiper(swiperContainer, {
                    pagination: {
                        el: swiperPagination,
                        clickable: true,
                    },

                    navigation: {
                        nextEl: swiperNext,
                        prevEl: swiperPrev,
                    },

                    spaceBetween: 30,
                    fadeEffect: {
                        crossFade: false,
                    },

                    lazy: {
                        loadPrevNext: true,
                    },

                    autoplay: {
                        delay: autoplay * 1000,
                        disableOnInteraction: false,
                    },
                });
            }
        },
    };

let count = $('.image-text-carousel-widget').length;
for (var i = 0; i < count; i++) {
    ImageTextCarousel.init($($('.image-text-carousel-widget')[i]).attr("id"))
}