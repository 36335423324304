
import '@/scss/main.scss';

import $ from 'jquery';

// Vendor Files
// import 'axios';
import 'lazysizes';
import 'moment';
import 'masonry-layout/masonry.js';
import './vendor/foundation';
import 'sticky-js/dist/sticky.compile.js';
import Masonry from 'masonry-layout/masonry.js';

// Modules
import './modules/viewmore.js';
import './modules/newslettersubscribe.js';
import './modules/imagetextcarousel.js';
import './modules/featurecardcarousel.js';
import './modules/cardgridcarousel.js';
import './modules/socialcarousel.js';
import './modules/newsarticledynamic.js';
import './modules/newsarticle.js';
import './modules/hero.js';
import './modules/datapushga.js';
import './modules/userlogin.js';
import './modules/redboxherowidget.js';
import './modules/faqs.js';
import './modules/search.js';

// Global
$(function() {

    // Foundation
    $(document).foundation();

    // Nav
    $(".nav-loader").css({
        "display": "inline-block"
    });

    $(".nav-loader-mobile").css({
        "display": "block"
    });

    // Add Days
    Date.prototype.addDays = function (days) {
        var dat = new Date(this.valueOf());
        dat.setDate(dat.getDate() + days);
        return dat;
    };

    // Masonry
    let masonryGrid = document.querySelector('.masonry-grid');
    if (masonryGrid) {
        let msnry = new Masonry( masonryGrid, {
            itemSelector: '.grid-item',
            columnWidth: 200
        });
    }

    // Filter Toggle
    $('.filter-toggle').on('click', function (e) {
        e.preventDefault();
        $('.more-filters').slideToggle(300);
        $('.filter-toggle > .more').toggleClass("hide");
        $('.filter-toggle > .less').toggleClass("hide");
    });

    $('.filter-toggle-resources').on('click', function (e) {
        e.preventDefault();
        $('.filters-area').slideToggle(300);
    });

    // Apply Button
    $('.btn-apply').on('click', function (e) {
        e.preventDefault();
        $('.filters-area').slideToggle(300);
    });

    // Clear Button
    $('.btn-clear').on('click', function (e) {
        e.preventDefault();
        $('input[type=checkbox]').prop('checked', false);
    });

    // Input switch
    $('.switch-input').on('click', function (e) {
        if ((this).id != 'usaon' && (this).id != 'usaoff') {
            if ((this).id == "mapoff") {
                $('#without-map').show();
                $('#with-map').hide();
            } else {
                $('#without-map').hide();
                $('#with-map').show();
            }
        }
    });

    // Tabs section
    $("#tabs-section").on("change.zf.tabs", function (event) {
        setTimeout(function () {
            $('html,body').animate({
                scrollTop: 0
            }, 'fast');
        }, 10); //Adjust to match slideSpeed
    });

    // Post Category
    $('.postCategory').on('change', function () {
        window.location.href = $('.postCategory').val();
    });

    // Dropdown Button
    $('#dropdown-button').on('click', function () {
        window.location = $('#dropdown-select').val();
    });

    // Search Field
    $(".search-field").keyup(function (e) {
        $('.search-reset').show();
    });

    // Search reset
    $(".search-reset").on('click', function (e) {
        $('.search-reset').hide();
    });

    // Image CTA grid
    $.each($(".image-cta-grid-widget"), function () {
        $this = $(this);
        var loadmore = $this.find(".load-more")
        $(loadmore).click(function (e) {
            e.preventDefault();
            for (var i = 0; i <= 5; i++) {
                if ($this.find(".load-more-cell:eq(" + i + ")").length) {
                    $this.find(".load-more-cell:eq(" + i + ")").show()
                } else {
                    loadmore.hide()
                }
            }
        });
    });

    let bannerWidget = document.querySelector(".banner-widget");
    if (bannerWidget) {
        let bannerClasses = document.querySelectorAll('.banner-active');
        let bannerSession = sessionStorage.getItem("banner") ;

        if (bannerSession != "clicked") {
            bannerWidget.classList.remove("d-none")
        }
        if (bannerSession == "clicked") {
            bannerClasses.forEach(element => element.classList.remove("banner-active"));
        }
    }

    let bannerCloser = document.querySelector(".close-banner");
    if (bannerCloser) {
        bannerCloser.addEventListener('click', function () {
            let bannerWidget = document.querySelector(".banner-widget");
            bannerWidget.classList.add("d-none");

            let bannerClasses = document.querySelectorAll('.banner-active');

            bannerClasses.forEach(element => element.classList.remove("banner-active"));

            sessionStorage.setItem("banner", "clicked");
        });
    }
});

// Lazy load
document.addEventListener('lazybeforeunveil', function (e) {
    var bg = e.target.getAttribute('data-bg');
    if (bg) {
        e.target.style.backgroundImage = 'url(' + bg + ')';
    }
});

////////////////////////////////////////////
//
// Logo Resize on scroll
//
////////////////////////////////////////////

window.addEventListener('scroll', function() {
    let scroll = {
		x: window.pageXOffset,
		y: window.pageYOffset
	};

	let logo = document.querySelector(".logo");
    if (logo) {
        if (scroll.y >= 125) {
            logo.classList.remove("big-logo");
        } else {
            logo.classList.add("big-logo");
        }
    }
});


let flipCard = document.querySelectorAll(".flip-card");
if (flipCard) {
    flipCard.forEach(card => {
        card.addEventListener('click', function () {
            card.classList.toggle('flip');
        });
    });
}


// Bootstrap import
import './vendor/bootstrap';
(function () {
    // Adding to the navbar toggler
    let navbarSideCollapse = document.querySelector('#navbarSideCollapse');
    if (navbarSideCollapse) {
        navbarSideCollapse.addEventListener('click', function () {
            document.querySelector('.offcanvas-collapse').classList.toggle('open');
            this.classList.toggle('open');
        });
    }
})();

document.addEventListener("DOMContentLoaded", function() {
    let bigtext = document.querySelector(".bigtext");
    if (bigtext) {
        let bigtextContent = bigtext.querySelector(".bigtext-content");
        if (bigtextContent) {
            let textCount = bigtextContent.innerText.length;
            if (textCount < 12) {
                bigtextContent.style.fontSize = "14vw";
            } else {
                let textRatio = textCount * 0.3;
                let textSize = 14 - textRatio;
                if (textSize < 5 ) {
                    textSize = 5;
                }
                bigtextContent.style.fontSize = textSize + "vw";
            }
        }
    }
});

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR")
    });
}